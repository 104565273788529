import React, { useEffect, useState } from "react";
import myImage from "../../assets/img/carousel-1.jpg";
import imgCat1 from "../../assets/img/cat-1.jpg";
import imgCat2 from "../../assets/img/cat-2.jpg";
import imgCat3 from "../../assets/img/cat-3.jpg";
import imgCat4 from "../../assets/img/cat-4.jpg";
import { Carousel } from "react-responsive-carousel";
import { Modal, Button } from "react-bootstrap";
import "./Login.css";
import LoginForm from "../Login/LoginForm";
import { enumUserType } from "../../objectMgr/User";


interface LoginProps {
  userType: enumUserType;
}

const Login : React.FC<LoginProps> = ({userType}) => {
  const [showModal, setShowModal] = useState(true);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  useEffect(() => {
//    alert("hi" + showModal);
  }, [showModal]);

  return (
    <div>
      <div className="container-fluid p-0 mb-5">
        <div className="owl-carousel header-carousel position-relative">
          <div className="owl-carousel-item position-relative">
            {/* <img className="img-fluid" src={myImage} width={"100%"} alt="" /> */}
            <div
              className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
              style={{ background: "rgba(24, 29, 56, .7)" }}
            >
          
            </div>
          </div>
        </div>
      </div>


     <LoginForm userType={userType} show={showModal} handleClose={handleClose}  />
    </div>
  );
};

export default Login;
