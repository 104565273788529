import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface ForgotPasswordProps {
  show: boolean;
  handleClose: () => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ show, handleClose }) => {
  const [email, setEmail] = useState("");
  const [showError, setShowError] = useState(false);

  const handleResetPassword = () => {
    // Implement your logic to handle the password reset
    // For example, you can send a request to the server to send a reset email
    // You might want to add loading indicators, error handling, etc.

    // For now, let's just show an error if the email is empty
    if (!email) {
      setShowError(true);
      return;
    }

    // Add your logic here to initiate the password reset
    // Example: resetPassword(email);

    // Close the modal after handling the reset
    handleClose();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setShowError(false); // Hide the error when the user starts typing
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Forgot Password</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-item justify-content-center modal-body forgotpassword-body" >
        <form>
          <div className="mb-3" style={{marginTop:'10px'}}>
            <label htmlFor="forgotPasswordEmail" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="forgotPasswordEmail"
              placeholder="Enter your email"
              value={email}
              onChange={handleChange}
              style={{ minWidth: "300px", width: "400px" }}  // Set width to 200px
            />
            {showError && <div className="error-message">Email is required</div>}
          </div>

          <Button variant="primary" onClick={handleResetPassword} style={{marginTop:'30px'}}>
            Reset Password
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ForgotPassword;
