// routes.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Participant from '../pages/Participant/Participant';
import EventComponent from '../pages/EventComponent/EventComponent';
import EventParticipant from '../pages/EventParticipant/EventParticipant';
import Buyer from '../pages/Buyer/Buyer';
import BidData from '../pages/BidData/BidData';
import Login from '../pages/Login/Login';
import { enumUserType } from '../objectMgr/User';



const routes = [
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/participant/add',
    element: <Participant mode="add"/>
  },

  {
    path: '/participant/add',
    element: <Participant mode="edit"/>
  },
  {
    path: '/event',
    element: <EventComponent />
  },

  {
    path: '/event-participant',
    element: <EventParticipant />
  },
  {
    path: '/buyer',
    element: <Buyer />
  },
  {
    path: '/biddata',
    element: <BidData />
  },
  {
    path: '/login/participant',
    element: <Login  userType={enumUserType.Participant}/>
  },
  {
    path: '/login/buyer',
    element: <Login  userType={enumUserType.Buyer}/>
  },
  // {
  //   path: '/customer/add',
  //   element: <Customer  mode="add" />
  // },
  //  { 
  //   path: '/customer/edit/:customerId',
  //   element: <Customer  mode="edit" />
  // },
  // {
  //   path: '/property',
  //   element: <PropertyList />
  // },

  // {
  //   path: '/property/add',
  //   element: <Property  mode="add" />
  // },
  //  { 
  //   path: '/property/edit/:customerId/:propertyId',
  //   element: <Property  mode="edit" />
  // },
  // {
  //   path: '/floor',
  //   element: <FloorList />
  // },
  // {
  //   path: '/floor/add',
  //   element: <Floor  mode="add" />
  // },
  //  { 
  //   path: '/floor/edit/:customerId/:propertyId/:floorId',
  //   element: <Floor  mode="edit" />
  // },

  // {
  //   path: '/license',
  //   element: <LicenseList />
  // },
  // {
  //   path: '/license/add',
  //   element: <License  mode="add" />
  // },
  // { 
  //   path: '/license/edit/:customerId/:licenceId',
  //   element: <License  mode="edit" />
  // },

  // {
  //   path: '/snaps',
  //   element: <SnapList />
  // },
  // {
  //   path: '/snap/add',
  //   element: <Snap  mode="add" />
  // },
  //  { 
  //   path: '/snap/edit/:customerId/:propertyId/:floorId/:snapId',
  //   element: <Snap  mode="edit" />
  // },

];

export default routes;