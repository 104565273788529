import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { TUser, TUserData, enumUserType, isValidUser } from "../../objectMgr/User";
import { userSchema } from "../../components/FormValidation/UserValidation";
import { ValidationError } from "yup";
import { RSAService } from "../../utils/RSAService";
import Alert, { enumAlertType } from "../../assets/Alert";
import "./LoginForm.css";
import ForgotPassword from "./ForgotPassword";

interface LoginFormProps {
  userType: enumUserType;
  show: boolean;
  handleClose: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ userType, show, handleClose }) => {
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>();
  const [showErrors, setShowErrors] = useState(false);
  const [alertMsgType, setAlertMsgType] = useState(enumAlertType.Message);
  const [showLoginForm, setShowLoginForm] = useState(show);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const [user, setUser] = useState<TUser>({
    UserId: "",
    UserName: "",
    Password: "",
    EmailId: "",
    UserType: userType,
    IsActive: 0
  });
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const navigate = useNavigate();

  // Handle form submission
  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    
    try {
      debugger;

      if (user != null) {
        await userSchema.validate(user, { abortEarly: false });
        setErrorMessages({});
      } else {
        setShowAlert(true);
        setAlertMsg("User is not allowed to be blank");
        return;
      }
    } catch (validationError) {
      const validationErrors: Record<string, string> = {};

      (validationError as ValidationError).inner.forEach(
        (error: ValidationError) => {
          if (error.path) {
            validationErrors[error.path] = error.message;
          } else {
            validationErrors._form = error.message;
          }
        }
      );

      await setErrorMessages(validationErrors);

      setShowErrors(true);
      return;
    }


    setUser((prevUser) => ({
      ...prevUser,
      Password: RSAService.EncryptionToBas64(JSON.stringify(user.Password)),
    }));


    const response: Promise<TUserData> = isValidUser(user);
    response
      .then(({ Data, Msg }) => {
        debugger;
        if (Msg !== "") {
          setAlertMsg(Msg);
          debugger;
          //setShowAlert(true);

        } else {
          setAlertMsg("Saved Successfully.");
          setTimeout(() => {
            navigate("/home");
          }, 3000); // 1000 milliseconds = 1 second
        }


      })
      .catch((error) => {
        console.error("Error fetching Property data:", error);
        setShowAlert(true);
        setAlertMsg("An error occurred while fetching User data.");
      });
  };





  const handleNewRegistration = () => {
    navigate('/participant/add');
  };

  const handleForgotPassword = () => {
    setShowLoginForm(false);
    setShowForgotPassword(true);
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debugger;
    const { name, value } = event.target;

    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));

  };


  return (
    <>
      <Modal className="loginForm" show={showLoginForm} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex align-items-center justify-content-center"   >
          <form>
            <div className="mb-3">
              <label htmlFor="EmailId" className="form-label">
                Email Id
              </label>
              <input
                type="text"
                className="form-control"
                id="EmailId"
                placeholder="Enter your Email ID"
                name="EmailId"
                value={user.EmailId}
                onChange={onChange}
                style={{ minWidth: "300px", width: "400px" }}  // Set width to 200px
              />
              {showErrors && errorMessages?.EmailId !== undefined ? (
                <div className="error-message">{errorMessages.EmailId}</div>
              ) : null}

            </div>

            <div className="mb-3">
              <label htmlFor="Password"
                className="form-label"
              >
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="Password"
                placeholder="Enter your password"
                value={user.Password}
                onChange={onChange}
                name="Password"
                style={{ minWidth: "300px", width: "400px" }}  // Set width to 200px
              />
              {showErrors && errorMessages?.Password !== undefined ? (
                <div className="error-message">{errorMessages.Password}</div>
              ) : null}

              {alertMsg != "" && <div className="error-message">{"Wrong password or Not Registered or click Reset password’ to reset it."}</div>}


            </div>

            <Button variant="primary" onClick={handleLogin}>
              Login
            </Button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <p className="text-muted">
            <small>
              New Registration?{" "}
              <a href="#" onClick={handleNewRegistration}>
                Register Here
              </a>
            </small>
          </p>
          <p className="text-muted">
            <small>
              Forgot Password?{" "}
              <a href="#" onClick={handleForgotPassword}>
                Reset Password
              </a>
            </small>
          </p>
        </Modal.Footer>
        {showAlert && <Alert message={alertMsg} alertMsgType={alertMsgType} />}
      </Modal>
      <ForgotPassword show={showForgotPassword} handleClose={() => setShowForgotPassword(false)} />
    </>
  );
};

export default LoginForm;
