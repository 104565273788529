import React, { useEffect, useState } from "react";
 import myImage from "../../assets/img/carousel-1.jpg";

import imgCat1 from "../../assets/img/cat-1.jpg";
import imgCat2 from "../../assets/img/cat-2.jpg";
import imgCat3 from "../../assets/img/cat-3.jpg";
import imgCat4 from "../../assets/img/cat-4.jpg";

import { Carousel } from "react-responsive-carousel";
import { Modal, Button } from "react-bootstrap";
import "./Home.css";
import LoginForm from "../Login/LoginForm";
import { enumUserType } from "../../objectMgr/User";
import PrathTest from "../Login/PrathTest";

const Home = () => {
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  useEffect(() => {
    //alert("hi" + showModal);
  }, [showModal]);

  return (
    <div>
      <div className="container-fluid p-0 mb-5">
        <div className="owl-carousel header-carousel position-relative">
          <div className="owl-carousel-item position-relative">
            <img className="img-fluid" src={myImage} width={"100%"} alt="" />
            <div
              className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
              style={{ background: "rgba(24, 29, 56, .7)" }}
            >
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-sm-10 col-lg-8">
                    <h5 className="text-primary text-uppercase mb-3 animate__animated animate__slideInDown">
                      Let's Joint with one of leading youth empowerment team
                    </h5>
                    <h1 className="display-3 text-white animate__animated animate__slideInDown">
                      The Best Online Event Participation Platform
                    </h1>

                    <Button
                      id="btnNewParticipant"
                      variant="primary"
                      onClick={handleShow}
                      className="btn  btn-primary py-md-3 px-md-5 me-3 animate__animated animate__slideInLeft"
                    >
                      New Participant
                    </Button>

                    <Button
                      id="btnNewEvent"
                      variant="primary"
                      onClick={handleShow}
                      className="btn btn-primary py-md-3 px-md-5 animate__animated animate__slideInRight"
                    >
                      Join Event
                    </Button>

                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Categories Start  */}
      <div className="container-xxl py-5 category">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Categories
            </h6>
            <h1 className="mb-5">Courses Categories</h1>
          </div>
          <div className="row g-3">
            <div className="col-lg-7 col-md-6">
              <div className="row g-3">
                <div
                  className="col-lg-12 col-md-12 wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <a
                    className="position-relative d-block overflow-hidden"
                    href=""
                  >
                    <img className="img-fluid" src={imgCat1} alt="" />
                    <div
                      className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                      style={{ margin: "1px" }}
                    >
                      <h5 className="m-0">Web Design</h5>
                      <small className="text-primary">49 Courses</small>
                    </div>
                  </a>
                </div>
                <div
                  className="col-lg-6 col-md-12 wow zoomIn"
                  data-wow-delay="0.3s"
                >
                  <a
                    className="position-relative d-block overflow-hidden"
                    href=""
                  >
                    <img className="img-fluid" src={imgCat2} alt="" />
                    <div
                      className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                      style={{ margin: "1px" }}
                    >
                      <h5 className="m-0">Graphic Design</h5>
                      <small className="text-primary">49 Courses</small>
                    </div>
                  </a>
                </div>
                <div
                  className="col-lg-6 col-md-12 wow zoomIn"
                  data-wow-delay="0.5s"
                >
                  <a
                    className="position-relative d-block overflow-hidden"
                    href=""
                  >
                    <img className="img-fluid" src={imgCat3} alt="" />
                    <div
                      className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                      style={{ margin: "1px" }}
                    >
                      <h5 className="m-0">Video Editing</h5>
                      <small className="text-primary">49 Courses</small>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-5 col-md-6 wow zoomIn"
              data-wow-delay="0.7s"
              style={{ minHeight: "350px" }}
            >
              <a
                className="position-relative d-block h-100 w-100 overflow-hidden"
                href=""
              >
                <img
                  className="img-fluid position-absolute w-100 h-100"
                  src={imgCat4}
                  style={{ width: "100%!important" }}
                  alt=""
                />
                <div
                  className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3"
                  style={{ margin: "1px" }}
                >
                  <h5 className="m-0">Online Marketing</h5>
                  <small className="text-primary">49 Courses</small>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Categories End */}
    
     {showModal &&  <LoginForm userType={enumUserType.Admin}  show={showModal} handleClose={handleClose} />}
     
     
    </div>
  );
};

export default Home;
