import axios, { AxiosResponse } from "axios"
import { RSAService } from "../utils/RSAService";

export type TUser = {
  UserId: string,
  UserName: string,
  Password: string,
  EmailId: string,
  UserType: enumUserType,
  IsActive: number,  
}


export enum enumUserType {
  Participant = 'P',
  Buyer = 'B',
  Admin = "A"
}



export type TTUserData = {
  Data: Array<Object> | [];
  Msg: string;
  isSuccess: boolean;
};

export type TUserData = {
  Data: TUser;
  Msg: string;
  isSuccess: boolean;
}

export class UserClass implements TUser {
  UserId: string="";
  UserName: string="";
  Password: string="";
  EmailId: string="";
  UserType: enumUserType=enumUserType.Participant;
  IsActive: number=0;


}


export const isValidUser = async (User: TUser): Promise<TUserData> => {
  let objData: TUserData = { Data: new UserClass(), Msg: '', isSuccess : false };

  try {
    debugger;
      let url = "http://localhost:49445/EventManagement.asmx/isValidUser";
      
      
 // Encrypt only the password
 const encryptedUser: TUser = {
  ...User,
  Password: RSAService.EncryptionToBas64(User.Password),
};
      
      
      const response : AxiosResponse= await  axios
        .post(url, 
          // {strUser: JSON.stringify(User) },
          {strUser: RSAService.EncryptionToBas64(JSON.stringify(encryptedUser)) },          
          {headers: {
            "Content-Type": "application/json",
          },
        });

      debugger;
    if (response.status === 200) {
      let responseData = response.data;
      responseData = responseData.replace('{"d":null}', '');
      objData= JSON.parse(responseData);
      if (objData.isSuccess) {
          //continue
      } else {
        //objData.Msg = objData.Msg;
      }
    }
    else {
      objData.Msg = "Error Connection problem";
    }
    return objData;

  }
  catch (error) {
    objData.Msg = "Error Connection problem";
    return objData;
  }

}
