import React, { MouseEventHandler, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  enumGender,
  enumTShirtSize,
  enumBloodGroup,
  TParticipant,
  TParticipantData,
  saveParticipant,
} from "../../objectMgr/participant";
import "./Participant.css";
import { participantSchema } from "../../components/FormValidation/ParticipantValidation";
import { ValidationError, bool } from "yup";
import Alert, { enumAlertType } from "../../assets/Alert";
// import nodemailer from 'nodemailer';

interface ParticipantProps {
  mode: "add" | "edit";
}

type StateSetter = React.Dispatch<React.SetStateAction<any>>;

const Participant: React.FC<ParticipantProps> = ({ mode }) => {
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>();
  const [showErrors, setShowErrors] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertMsgType, setAlertMsgType] = useState(enumAlertType.Message);
  const [reEnterPassword, setReEnterPassword] = useState("");
  const [selectedGender, setSelectedGender] = useState(enumGender.Male);
  const [selectedTShitSize, setSelectedTShitSize] = useState(undefined);

  const [selectedBloodGroup, setSelectedBloodGroup] = useState(undefined);
  const navigate = useNavigate();
  // State for form fields
  const [participant, setParticipant] = useState<TParticipant>({
    ParticipantId: "",
    EmailId: "",
    AdharCardId: "",
    RegistrationNumber: "",
    Surname: "",
    Name: "",
    FatherName: "",
    Gender: enumGender.Male,
    Address: "",
    City: "",
    State: "",
    Country: "",
    PinCode: "",
    MobileNumber: "",
    EmergencyContactNumber: "",
    BloodGroup: enumBloodGroup.A_Positive,
    Password: "",
    TShirtSize: enumTShirtSize.Size40,
    IsDivyang: 0,
    Photo: "",
    Active: 0,
    IsStudent: 0,
    SchoolName: "",
    IsEmployee: 0,
    OrganizationName: "",
  });

  // State for the error message
  const [rePasswordError, setRePasswordError] = useState<string>("");

  // Handle form field changes

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { name, value, type  } = event.target;

    if (type === "checkbox") {
      
      const checkboxValue = (event.target as HTMLInputElement).checked ? 1 : 0;
      debugger;
      setParticipant((prevParticipant) => ({
        ...prevParticipant,
        [name]: checkboxValue ? 1 : 0,
      }));
    // Handle checkbox logic
    } else {
      setParticipant((prevParticipant) => ({
        ...prevParticipant,
        [name]: value,
      }));
    }

    if (name === "RePassword") {
      setReEnterPassword(value);
      setRePasswordError("");
    }
  };

  const onLostFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    try {
      const { name, value } = event.target;

      // Compare RePassword with Password and update error message
      if (name === "RePassword") {
        if (value !== participant.Password) {
          setReEnterPassword(value);
          setRePasswordError("Passwords do not match");
        } else {
          setReEnterPassword(value);
          setRePasswordError("");
        }
      }
    } catch (error) {
      console.error("Error in onLostFocus:", error);
    }
  };
  // Handle form submission

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      if (participant != null) {
        // Validate participant object using participantSchema
        await participantSchema.validate(participant, { abortEarly: false });
        setErrorMessages({});
      } else {
        setShowAlert(true);
        setAlertMsg("Participant object is not allowed to be blank");

        return;
      }

      // Assuming saveParticipant is an asynchronous function that saves participant data
      const response: Promise<TParticipantData> = saveParticipant(
        mode,
        participant
      );

      response
        .then(({ Data, Msg }) => {
          if (Msg !== "") {
            setAlertMsg("Error : " + Msg);
            setAlertMsgType(enumAlertType.Errror);

            setShowAlert(true);
            const timer = setTimeout(() => {
              setShowAlert(false);
              setAlertMsg("");
            }, 2000);
          } else {
            setAlertMsg("Saved Successfully.");
            setAlertMsgType(enumAlertType.Message);
            setShowAlert(true);

            //sendEmail();
            

            setTimeout(() => {
              
              navigate("/");
            }, 3000); // 1000 milliseconds = 1 second
          }
        })
        .catch((error) => {
          console.error("Error saving participant data:", error);
          setShowAlert(true);
          setAlertMsg("An error occurred while saving participant data.");
        });
    } catch (validationError) {
      const validationErrors: Record<string, string> = {};

      (validationError as ValidationError).inner.forEach(
        (error: ValidationError) => {
          if (error.path) {
            validationErrors[error.path] = error.message;
          } else {
            validationErrors._form = error.message;
          }
        }
      );

      await setErrorMessages(validationErrors);

      setShowErrors(true);
      return;
    }
  };

  const handleSelect = (
    e: React.ChangeEvent<HTMLSelectElement>,
    setState: StateSetter
  ) => {
    const { name, value } = e.target;
    setState(value);

    //setIsDataLoaded(true);
    setParticipant(
      (prevParticipant) =>
        ({
          ...prevParticipant,
          [name]: value,
        } as TParticipant)
    );
  };

  const onImageChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0];
    const { name } = event.target;
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64 = reader.result as string;

        setParticipant(
          (prevParticipant) =>
            ({
              ...prevParticipant,
              [name]: base64,
            } as TParticipant)
        );
      };
      reader.readAsDataURL(file);
    }
  };

  
  // const sendEmail = async () => {
  //   try {
  //     const transporter = nodemailer.createTransport({
  //       service: 'Gmail',
  //       auth: {
  //         user: 'prathetech@gmail.com', // Your Gmail email address
  //         pass: 'etech@123', // Your Gmail password or App Password if using 2-factor authentication
  //       },
  //     });

  //     const mailOptions = {
  //       from: 'prathetech@gmail.com',
  //       to: 'prathetech@gmail.com',
  //       subject: 'Hello test subject',
  //       text: 'Hello test Message',
  //     };

  //     await transporter.sendMail(mailOptions);
  //     console.log('Email sent successfully!');
  //   } catch (error) {
  //     console.error('Error sending email:', error);
  //   }
  // };

  return (
    <div>
      <div className="container-fluid form-container">
        <h2 id="heading">Participant </h2>
        {/* <form id="participant-form" action="handleSubmit"> */}
        <form id="participan-form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="Surname">Surname</label>
              </div>
              <div className="error-container"></div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="Surname"
                  name="Surname"
                  //required
                  value={participant.Surname}
                  onChange={onChange}
                />
              </div>
              {showErrors && errorMessages?.Name !== undefined ? (
                <div className="error-message">{errorMessages.Surname}</div>
              ) : null}
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="Name">Name</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="Name"
                  name="Name"
                  //required
                  value={participant.Name}
                  onChange={onChange}
                />
              </div>
              {showErrors && errorMessages?.Name !== undefined ? (
                <div className="error-message">{errorMessages.Name}</div>
              ) : null}
            </div>
          </div>

          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="FatherName">Father Name</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="FatherName"
                  name="FatherName"
                  //required
                  value={participant.FatherName}
                  onChange={onChange}
                />
              </div>
              {showErrors && errorMessages?.FatherName !== undefined ? (
                <div className="error-message">{errorMessages.FatherName}</div>
              ) : null}
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="Gender">Gender</label>
              </div>
            </div>

            <div className="col-md-4">
              <div
                className="form-group"
                style={{ display: "flex", justifyContent: "left" }}
              >
                <label>
                  <input
                    type="radio"
                    name="Gender"
                    value={enumGender.Male}
                    checked={participant.Gender === enumGender.Male}
                    onChange={onChange}
                  />
                  Male
                </label>

                <label>
                  <input
                    type="radio"
                    name="Gender"
                    value={enumGender.Female}
                    checked={participant.Gender === enumGender.Female}
                    onChange={onChange}
                  />
                  Female
                </label>
              </div>
              {showErrors && errorMessages?.Gender !== undefined ? (
                <div className="error-message">{errorMessages.Gender}</div>
              ) : null}
            </div>
          </div>

          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="Password">Password</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  id="Password"
                  name="Password"
                  //required
                  value={participant.Password}
                  onChange={onChange}
                />
              </div>
              {showErrors && errorMessages?.Password !== undefined ? (
                <div className="error-message">{errorMessages.Password}</div>
              ) : null}
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="RePassword">Retype Password</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  id="RePassword"
                  name="RePassword"
                  //required
                  value={reEnterPassword}
                  onChange={onChange}
                  onBlur={onLostFocus}
                />
              </div>
              {rePasswordError !== "" ? (
                <div className="error-message">{rePasswordError}</div>
              ) : null}
            </div>
          </div>

          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="EmailId">Email Id</label>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  id="EmailId"
                  name="EmailId"
                  //required
                  value={participant.EmailId}
                  onChange={onChange}
                />
              </div>

              {showErrors && errorMessages?.EmailId !== undefined ? (
                <div className="error-message">{errorMessages.EmailId}</div>
              ) : null}
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="AdharCardId">Adhar Card No.</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="AdharCardId"
                  name="AdharCardId"
                  //required
                  value={participant.AdharCardId}
                  onChange={onChange}
                />
              </div>
              {showErrors && errorMessages?.AdharCardId !== undefined ? (
                <div className="error-message">{errorMessages.AdharCardId}</div>
              ) : null}
            </div>
          </div>

          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="Address">Address</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <textarea
                  className="form-control"
                  id="Address"
                  name="Address"
                  //required
                  value={participant.Address}
                  onChange={onChange}
                />
              </div>
              {showErrors && errorMessages?.Address !== undefined ? (
                <div className="error-message">{errorMessages.Address}</div>
              ) : null}
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="City">City</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="City"
                  name="City"
                  //required
                  value={participant.City}
                  onChange={onChange}
                />
              </div>
              {showErrors && errorMessages?.City !== undefined ? (
                <div className="error-message">{errorMessages.City}</div>
              ) : null}
            </div>
          </div>

          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="Country">Country</label>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="Country"
                  name="Country"
                  ////required
                  value={participant.Country}
                  onChange={onChange}
                />
              </div>
              {showErrors && errorMessages?.Country !== undefined ? (
                <div className="error-message">{errorMessages.Country}</div>
              ) : null}
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="PinCode">Pin Code</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="PinCode"
                  name="PinCode"
                  //required
                  value={participant.PinCode}
                  onChange={onChange}
                />
              </div>
              {showErrors && errorMessages?.PinCode !== undefined ? (
                <div className="error-message">{errorMessages.PinCode}</div>
              ) : null}
            </div>
          </div>

          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="MobileNumber">Mobile Number</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="MobileNumber"
                  name="MobileNumber"
                  //required
                  value={participant.MobileNumber}
                  onChange={onChange}
                />
              </div>
              {showErrors && errorMessages?.MobileNumber !== undefined ? (
                <div className="error-message">
                  {errorMessages.MobileNumber}
                </div>
              ) : null}
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="EmergencyContactNumber">Emergency No.</label>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="EmergencyContactNumber"
                  name="EmergencyContactNumber"
                  //required
                  value={participant.EmergencyContactNumber}
                  onChange={onChange}
                />
              </div>
              {showErrors &&
              errorMessages?.EmergencyContactNumber !== undefined ? (
                <div className="error-message">
                  {errorMessages.EmergencyContactNumber}
                </div>
              ) : null}
            </div>
          </div>

          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="BloodGroup">BloodGroup</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <select
                  id="BloodGroup"
                  className="form-select dropdown-max-width"
                  value={selectedBloodGroup}
                  name="BloodGroup"
                  onChange={(e) => {
                    handleSelect(e, setSelectedBloodGroup);
                  }}
                >
                  <option value="0">Select BloodGroup...</option>
                  <option value="1">A+</option>
                  <option value="2">A-</option>
                  <option value="3">B+</option>
                  <option value="4">B-</option>
                  <option value="5">O+</option>
                  <option value="6">O-</option>
                  <option value="7">AB+</option>
                  <option value="8">AB-</option>
                </select>
              </div>
              {showErrors && errorMessages?.BloodGroup !== undefined ? (
                <div className="error-message">{errorMessages.BloodGroup}</div>
              ) : null}
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="TShirtSize">Tshirt Size</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <select
                  id="TShirtSize"
                  className="form-select dropdown-max-width"
                  value={selectedTShitSize}
                  name="TShirtSize"
                  onChange={(e) => {
                    handleSelect(e, setSelectedTShitSize);
                  }}
                >
                  <option value="0">Select size...</option>
                  <option value="36">Small-36</option>
                  <option value="38">Medium-38</option>
                  <option value="40">Large-40</option>
                  <option value="42">Extra Large-42</option>
                  <option value="44">Double XL-44</option>
                </select>
              </div>
              {showErrors && errorMessages?.TShitSize !== undefined ? (
                <div className="error-message">{errorMessages.TShitSize}</div>
              ) : null}
            </div>
          </div>

          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="IsDivyang">IsDivyang</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group" style={{ justifyContent: "left" }}>
                <input
                  name="IsDivyang"
                  type="checkbox"
                  checked={participant.IsDivyang == 1 ? true : false}
                  onChange={onChange}
                />
              </div>
              {showErrors && errorMessages?.IsDivyang !== undefined ? (
                <div className="error-message">{errorMessages.IsDivyang}</div>
              ) : null}
            </div>
          </div>

          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="IsStudent">Are you Student?</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group" style={{ justifyContent: "left" }}>
                <input
                  name="IsStudent"
                  type="checkbox"
                  checked={participant.IsStudent == 1 ? true : false}
                  onChange={onChange}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="SchoolName">School Name</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="SchoolName"
                  name="SchoolName"
                  //required
                  value={participant.SchoolName}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="IsEmployee">Are you Employee?</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group" style={{ justifyContent: "left" }}>
                <input
                  name="IsEmployee"
                  type="checkbox"
                  checked={participant.IsEmployee === 1 ? true : false}
                  onChange={onChange}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="OrganizationName">Organization Name</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="OrganizationName"
                  name="OrganizationName"
                  //required
                  value={participant.OrganizationName}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-2" style={{ gridRow: "span 2" }}>
              <div className="form-group">
                <label htmlFor="Photo">Photograph</label>
              </div>
            </div>
            <div className="col-md-4" style={{ gridRow: "span 2" }}>
              <div className="form-group">
                <input
                  type="file"
                  className="form-input"
                  id="Photo"
                  name="Photo"
                  accept="image/*" // Specify accepted image types
                  onChange={onImageChange}
                  width={"100px"}
                />
              </div>
              <img
                src={participant?.Photo}
                style={{ width: "100px", height: "100px" }}
              ></img>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>

              <button
                type="button"
                style={{ marginLeft: "20px" }}
                className="btn btn-primary"
                onClick={() => {
                  navigate("/");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
      {showAlert && <Alert message={alertMsg} alertMsgType={alertMsgType} />}
    </div>
  );
};

export default Participant;
