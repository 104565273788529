import * as yup from 'yup';

export const participantSchema = yup.object().shape({
    
    Surname: yup.string().required('Surname is Required'),
    Name: yup.string().required('Name is Required'),
    FatherName: yup.string().required('FatherName is Required'),
    Address : yup.string().required(),
    Gender: yup.string().required(),
    City   : yup.string().required(),
    //State   : yup.string().required(),
    Country   : yup.string().required(),
    PinCode: yup.string()
    .test('PinCode', 'Pin number must be empty or have exactly 6 digits', value => {
      if (!value) return true; // Allow empty value
      return /^[0-9]{6}$/.test(value);
    })
    .nullable(),
    MobileNumber   : yup.string().required(),
    EmergencyContactNumber   : yup.string().required(),
    // MobileNumber  : yup.string()
    // .test('digits', 'Phone number must have exactly 10 digits', value => {
    //   if (!value) return true; // Allow empty value
    //   return /^\d{10}$/.test(value);
    // }),
    // EmergencyContactNumber  : yup.string()
    // .test('digits', 'Phone number must have exactly 10 digits', value => {
    //   if (!value) return true; // Allow empty value
    //   return /^\d{10}$/.test(value);
    // }),
    EmailId: yup.string()
    .email('Invalid email format')
    .required('Email is required'),   
    BloodGroup: yup.string().required(),
    Password: yup.string().required(),
    TShirtSize: yup.string().required(),
    // IsDivyang: yup.string().required(),

    AdharCardId  : yup.string()
    .test('digits', 'AdharCardId number must have exactly 10 digits', value => {
      if (!value) return true; // Allow empty value
      return /^\d{10}$/.test(value);
    }),
  });

  
  
  
  
  