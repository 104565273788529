import axios, { AxiosResponse } from "axios"
import { RSAService } from "../utils/RSAService";
import { HOST_API } from "../utils/Config";

export type TParticipant = {
  ParticipantId: string,
  EmailId: string,
  AdharCardId: string,
  RegistrationNumber: string,
  Surname: string,
  Name: string,
  FatherName: string,
  Gender: enumGender,
  Address: string,
  City: string,
  State: string,
  Country: string,
  PinCode: string,
  MobileNumber: string,
  EmergencyContactNumber: string,
  BloodGroup: enumBloodGroup,
  Password: string,
  TShirtSize: enumTShirtSize,
  IsDivyang: number,
  Photo: string,
  Active: number,
  IsStudent: number,
  SchoolName: string,
  IsEmployee: number,
  OrganizationName: string
}


export enum enumGender {
  Male = 'M',
  Female = 'F',
}


export enum enumTShirtSize {
  Size36 = '36',
  Size38 = '38',
  Size40 = '40',
  Size42 = '42',
  Size44 = '44',
}


export enum enumBloodGroup {
  A_Positive = '1',
  A_Negative = '2',
  B_Positive = '3',
  B_Negative = '4',
  O_Positive = '5',
  O_Negative = '6',
  A_BPositive = '7',
  AB_Negative = '8',
}

export type TTParticipantData = {
  Data: Array<Object> | [];
  Msg: string;
  isSuccess: boolean;
};

export type TParticipantData = {
  Data: TParticipant;
  Msg: string;
  isSuccess: boolean;
}

export class ParticipantClass implements TParticipant {
  ParticipantId: string = "";
  EmailId: string = "";
  AdharCardId: string = "";
  RegistrationNumber: string = "";
  Surname: string = "";
  Name: string = "";
  FatherName: string = "";
  Gender: enumGender = enumGender.Male;
  Address: string = "";
  City: string = "";
  State: string = "";
  Country: string = "";
  PinCode: string = "";
  MobileNumber: string = "";
  EmergencyContactNumber: string = "";
  BloodGroup: enumBloodGroup = enumBloodGroup.A_Positive;
  Password: string = "";
  TShirtSize: enumTShirtSize = enumTShirtSize.Size40;
  IsDivyang: number = 0;
  Photo: string = "";
  Active: number = 0;
  IsStudent: number = 0;
  SchoolName: string = "";
  IsEmployee: number = 0;
  OrganizationName: string = "";

}

export const getParticipantList = async (): Promise<TTParticipantData> => {
  let objData: TTParticipantData = { Data: [], Msg: '', isSuccess: false };
  try {
    const url =  HOST_API + "/getParticipantList";
    debugger;
    const response: AxiosResponse = await axios
      .get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
      });


    if (response.status === 200) {
      if (response.data.isSuccess) {
        const responseData = response.data.Data;
        objData.Data = JSON.parse(responseData);
      } else {
        objData.Msg = response.data.Msg;
      }
    }
    else {

      objData.Msg = "Error Connection problem";
    }

    return objData;
  } catch (error) {

    objData.Msg = "Error..";
    return objData;
  }
}

export const loadParticipant = async (participantId: string): Promise<TParticipantData> => {
  let objData: TParticipantData = { Data: new ParticipantClass(), Msg: '', isSuccess: false };
  try {

    const url = HOST_API + "/loadParticipant";
    const response: AxiosResponse = await axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
        params: { ParticipantId: participantId }
      });

    if (response.status === 200) {
      let responseData = response.data;
      responseData = responseData.replace('{"d":null}', '');
      objData.Data = JSON.parse(responseData);
      debugger;
      if (response.data.isSuccess) {
      } else {
        objData.Msg = response.data.Msg;
      }
    }
    else {

      objData.Msg = "Error Connection problem";
    }

    return objData;

  } catch (error) {
    return objData;
  }
}



export const saveParticipant = async (mode: string, participant: TParticipant): Promise<TParticipantData> => {
  let objData: TParticipantData = { Data: new ParticipantClass(), Msg: '', isSuccess: false };

  try {
    debugger;

    // Encrypt only the password
 const encryptedParticipant: TParticipant = {
  ...participant,
  Password: RSAService.EncryptionToBas64(participant.Password),
};

    let url = "";

    if (mode === "add") {
      url = HOST_API + "/addParticipant";
    } else {
      url = HOST_API + "/updateParticipant";
    }

    const response: AxiosResponse = await axios
      .post(url,
        { strParticipant: JSON.stringify(encryptedParticipant) },
        {
          headers: {
            "Content-Type": "application/json",
          },
        });

    debugger;
    if (response.status === 200) {
      let responseData = response.data;
      responseData = responseData.replace('{"d":null}', '');
      objData = JSON.parse(responseData);
      debugger;
      if (objData.isSuccess) {
        //continue
      } else {
        //objData.Msg = objData.Msg;
      }
    }
    else {
      objData.Msg = "Error Connection problem";
    }
    return objData;

  }
  catch (error) {
    objData.Msg = "Error Connection problem";
    return objData;
  }

}

export const onDeleteParticipant = async (participantId: string): Promise<TParticipantData> => {
  let objData: TParticipantData = { Data: new ParticipantClass(), Msg: '', isSuccess: false };
  try {
   const url = HOST_API + "/deleteParticipant";

    const response: AxiosResponse = await axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
        params: { ParticipantId: participantId },
      });


    if (response.status === 200) {
      let responseData = response.data;
      responseData = responseData.replace('{"d":null}', '');
      objData = JSON.parse(responseData);
      if (objData.isSuccess) {
        //continue
      } else {
        //objData.Msg = objData.Msg;
      }
    }
    else {
      objData.Msg = "Error Connection problem";
    }
    return objData;

  } catch (error) {
    objData.Msg = "Error Connection problem";
    return objData;
  }
};