import React from "react";
import { Navbar, Nav, NavDropdown,Dropdown  } from "react-bootstrap";
//import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, BrowserRouter as Router, Route, Routes } from "react-router-dom";
import routing from "./routes/routes";

import "./App.css";
import "./assets/css/style.css";
import "./assets/css/bootstrap.min.css";
import "animate.css"; //npm Animate.css

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar bg="white" expand="lg" sticky="top" className="shadow p-0">
          <Navbar.Brand>
            <Link to="/" className="text-primary text-decoration-none">
              <i className="fa fa-book me-3"></i>eLEARNING
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarCollapse" />
          <Navbar.Collapse id="navbarCollapse">
            <Nav className="ms-auto p-4 p-lg-0 d-flex align-items-center">
              <Link to="/" className="nav-link nav-item">
                Home
              </Link>
              <Link to="/about" className="nav-link nav-item">
                About
              </Link>

              <NavDropdown title="Participant Panel" id="participant-dropdown">
                <NavDropdown.Item href="/event-participant/add">
                  New Event Registration
                </NavDropdown.Item>
                <NavDropdown.Item href="/event/list">
                  Event History
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Admin Panel" id="admin-dropdown">
                <NavDropdown.Item href="/participant/add">New Participant</NavDropdown.Item>
                <NavDropdown.Item href="/event/add">New Event</NavDropdown.Item>
                <NavDropdown.Item href="/event-participant/add">
                  New Event Registration
                </NavDropdown.Item>
                <NavDropdown.Item href="/buyer/add">
                  New Buyer Registration
                </NavDropdown.Item>
                <Dropdown.Divider />
                <NavDropdown.Item href="/participant/list">
                  Participant List
                </NavDropdown.Item>
                <NavDropdown.Item href="/event/list">
                  Event List
                </NavDropdown.Item>
                <NavDropdown.Item href="/buyer/list">
                  Buyer List
                </NavDropdown.Item>
                <NavDropdown.Item href="/event-participant/list">
                  Event Participant List
                </NavDropdown.Item>

                
                <NavDropdown.Item href="/bid-data/list">
                  Bid List
                </NavDropdown.Item>
              </NavDropdown>

              <Link to="/contact-us" className="nav-link nav-item">
                Contact Us
              </Link>

              <NavDropdown title="Login" id="login-dropdown">
                <NavDropdown.Item href="/login/participant">
                  Participant
                </NavDropdown.Item>
                <NavDropdown.Item href="/login/buyer">Buyer</NavDropdown.Item>
                <NavDropdown.Item href="/participant/list">
                  Admin
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        {/* Other Components and Routes */}
        <Routes>
          {routing.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
