import * as yup from 'yup';

export const userSchema = yup.object().shape({
    
    
    EmailId: yup.string()
    .email('Invalid email format')
    .required('Email is required'),       
    Password: yup.string().required(),
    
  });

  
  
  
  
  