import React, { useState, useEffect } from 'react';
import './Alert.css';



export enum enumAlertType {
  Message = 'M',
  Errror = 'F',
  Warning='W'
}
interface AlertProps {
  message: string;
  alertMsgType: enumAlertType;
}

const Alert: React.FC<AlertProps> = ({ message, alertMsgType }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return isVisible ? <div className="alert" style={alertMsgType==enumAlertType.Message? {backgroundColor:'blue'} : {backgroundColor:'red'}}>{message}</div> : null;
};

export default Alert;